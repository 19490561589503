import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { mockLineData as data } from "../../data/mockData";
import GlobalHeader from "../../components/GlobalComponets/GlobalHeader";

function AnalyzeBar() {
  const item = {
    Title: "Analytics",
  };
  return (
    <>
      <div>
        <GlobalHeader item={item} />
        <div className="bg-white rounded-md shadow mt-32 py-5 ps-5 w-5/6 mx-auto h-96">
          <ResponsiveLine
            data={data}
            margin={{ top: 50, right: 50, bottom: 80, left: 60 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: true,
              reverse: false,
            }}
            yFormat="3> 0,.112~"
            curve="cardinal"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 0,
              tickPadding: 13,
              tickRotation: 0,
              legend: " Analytics Chat Of Conversations",
              legendOffset: 60,
              legendPosition: "middle",
              truncateTickAt: 0,
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 11,
              tickRotation: 0,
              legend: "Coversation",
              legendOffset: -50,
              legendPosition: "middle",
              truncateTickAt: 100,
            }}
            colors={{ scheme: "set3" }}
            pointSize={5}
            pointColor={{ from: "color", modifiers: [] }}
            pointBorderWidth={1}
            pointBorderColor={{ from: "serieColor", modifiers: [] }}
            pointLabel="data.yFormatted"
            pointLabelYOffset={-4}
            enableArea={true}
            areaOpacity={0.6}
            enableSlices="x"
            enableTouchCrosshair={true}
            crosshairType="y"
            useMesh={true}
            debugMesh={true}
            legends={[
              {
                anchor: "top",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: -38,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 147,
                itemHeight: 33,
                itemOpacity: 0.75,
                symbolSize: 10,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            motionConfig="wobbly"
          />
        </div>
      </div>
    </>
  );
}

export default AnalyzeBar;
