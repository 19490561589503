// import { useAuthContext } from "../../context/AuthContext";
// import { extractTime } from "../../utils/extractTime";
// import useConversation from "../../zustand/useConversation";

// const Message = ({ message }) => {
//   const { authUser } = useAuthContext();
//   const { selectedConversation } = useConversation();
//   const fromMe = message.senderId === authUser._id;
//   const formattedTime = extractTime(message.createdAt);
//   const chatClassName = fromMe ? "chat-end" : "chat-start";
//   const profilePic = fromMe
//     ? authUser.profilePic
//     : selectedConversation?.profilePic;
//   const bubbleBgColor = fromMe ? "bg-blue-500" : "";

//   const shakeClass = message.shouldShake ? "shake" : "";

//   return (
//     <div className={`chat ${chatClassName}`}>
//       <div className="chat-image avatar">
//         <div className="w-10 rounded-full">
//           <img alt="Tailwind CSS chat bubble component" src={profilePic} />
//         </div>
//       </div>
//       <div
//         className={`chat-bubble text-white ${bubbleBgColor} ${shakeClass} pb-2`}
//       >
//         {message.message}
//       </div>
//       <div className="chat-footer opacity-50 text-xs  gap-1 pb-2 mb-1 inline-block items-center">
//         {formattedTime}
//       </div>
//     </div>
//   );
// };
// export default Message;

//STARTER CODE OF THIS FILE
import React, { useEffect, useState } from "react";

import { PiWarningCircleLight } from "react-icons/pi";
import { IoImageSharp, IoDocumentText, IoPlayCircle } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { FaArrowRightLong, FaArrowUpRightFromSquare } from "react-icons/fa6";
import { MdOutlineCall } from "react-icons/md";
import { HiLink } from "react-icons/hi2";

import { UseAuthContext } from "../../../context/AuthContext";
import { extractTime } from "../../../utils/extractTime";

const Message = ({ msg }) => {
  const { authUser } = UseAuthContext();

  const fromClientUser = authUser._id !== msg.senderId;

  const chatClass = fromClientUser ? "chat-start" : "chat-end";

  const timeStamp = extractTime(msg.createdAt);

  console.log("msg::", msg);

  return (
    <>
      <div className={`chat ${chatClass}`}>
        <div className="chat-image avatar">
          <div className="w-10 rounded-full">
            <img src="/assets/profileUser.jpg" alt="user avtar" />
          </div>
        </div>

        {msg.type === "text" && (
          <pre
            className={`chat-bubble max-w-[75%] whitespace-pre-wrap leading-tight ${
              fromClientUser
                ? " text-black bg-[#EBF5F3]"
                : "text-white bg-[#0A474C]"
            } font-normal`}
          >
            {msg.content}
          </pre>
        )}
        {msg.type === "button" && (
          <div className="">
            <ChatBubble type={msg?.type} buttonName={msg?.buttonName} />
          </div>
        )}
        {msg?.templateId?.configData && (
          <div className="">
            <TemplatePre
              data={msg?.templateId?.configData}
              mediaUrl={msg?.mediaUrl}
            />
          </div>
        )}

        <div className="chat-footer opacity-50 text-xs flex gap-1 items-center text-black">
          {timeStamp}
        </div>
      </div>
    </>
  );
};

export default Message;

function TemplatePre({ data, mediaUrl }) {
  const [isheader, setheader] = useState("");
  const [isbody, setbody] = useState("");
  const [isfooter, setfooter] = useState("");
  const [buttons, setbuttons] = useState([]);
  const [istitle, setTitle] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedmedia, setselectedmedia] = useState(null);
  const [istemplateid, settemplateid] = useState("");
  const [isCrausalData, setIsCrausalData] = useState(null);
  const components = data.components;

  useEffect(() => {
    let templateFormat = "";
    let headerText = "";
    let bodyText = "";
    let footer = "";
    let totalbutton = [];
    let cardsaArray;
    let CAROUSELtype;

    if (data?.components) {
      for (let j = 0; j < components.length; j++) {
        const component = components[j];

        console.log("Component looks like this:", component);
        console.log("mediaUrl:", mediaUrl);

        if (component.type === "HEADER" && component.format) {
          templateFormat = component.format;

          if (component.format === "Text") {
            headerText = component.text;
            console.log("Header looks like this in map function:", headerText);
          }
        } else if (component.type === "BODY" && component.text) {
          bodyText = component.text;
        } else if (component.type === "FOOTER" && component.text) {
          footer = component.text;
        } else if (component.type === "BUTTONS" && component.buttons) {
          totalbutton = totalbutton.concat(component.buttons);
        } else if (component.type === "CAROUSEL" && component?.cards) {
          cardsaArray = component.cards;
          CAROUSELtype = component.type;
        }
      }

      console.log("cardsaArray:", cardsaArray);
      console.log("CAROUSELtype:", CAROUSELtype);

      // Update the state with the extracted values
      setheader(templateFormat);
      setbody(bodyText);
      setfooter(footer);
      setbuttons(totalbutton);
      setTitle(headerText); // Assuming Title is set from headerText
      setIsCrausalData(cardsaArray);
    }
  }, [data]);

  let content = null;

  if (isheader?.toUpperCase() === "IMAGE") {
    // content = <IoImageSharp className="h-40 w-80 bg-orange-300 rounded-md" />;
    content = mediaUrl ? (
      <img src={mediaUrl} className="w-auto h-auto" alt="template image" />
    ) : (
      <IoImageSharp className="h-40 w-80 bg-orange-300 rounded-md" />
    );
  } else if (isheader?.toUpperCase() === "VIDEO") {
    content = mediaUrl ? (
      <video width={300} controls muted>
        {" "}
        <source src={mediaUrl} />
      </video>
    ) : (
      <div className="text-4xl text-blue-600 h-40 w-72 rounded-md">
        <IoPlayCircle className="h-40 w-72" />
      </div>
    );
  } else if (isheader?.toUpperCase() === "DOCUMENT") {
    content = mediaUrl ? (
      <iframe src={mediaUrl} frameborder="0"></iframe>
    ) : (
      <IoDocumentText className="h-40 w-72 text-red-400 " />
    );
  } else if (isheader?.toUpperCase() === "LOCATION") {
    content = (
      <div className="text-3xl text-red-600 h-40 w-72 rounded-md">
        <FaLocationDot className="h-40 w-72" />
      </div>
    );
  } else if (isheader?.toUpperCase() === "TEXT") {
    content = (
      <div className=" w-full bg-white">
        <p className="font-whatsapp-bold font-bold ml-2">
          {istitle.toLowerCase()}
        </p>
      </div>
    );
  }

  console.log("isCrausalData:", isCrausalData);

  return (
    <>
      {isCrausalData ? (
        <div>
          <div className="w-fit p-2 bg-white text-black rounded-md shadow-md mt-7 mx-auto mr-0 text-end">
            <p className="font-whatsapp">{isbody}</p>
            <div className="text-xs text-zinc-500 font-normal">{isfooter}</div>
          </div>
          <div className=" card bg-transparent mx-auto bg-white text-black rounded-md shadow-md  relative flex-row gap-2 overflow-auto mt-2 snap-mandatory snap-x  ">
            {/* <div className="h-20 w-auto ">{boxContent2}</div> */}

            {isCrausalData?.map((data, index) => (
              <div key={index} className="snap-center gap-4">
                {data.components?.map((component, i) => (
                  <div key={i}>
                    {/* <div className="h-40 w-72 text-xl"> */}
                    {component.type === "HEADER" && (
                      <div>
                        {component.format === "IMAGE" && (
                          <div className="flex h-40 w-fit text-xl">
                            <img src={mediaUrl[index]} alt="" />
                          </div>
                        )}
                        {component.format === "VIDEO" && (
                          <div className="flex h-40 w-fit text-xl">
                            <video controls src={mediaUrl[index]}></video>
                          </div>
                        )}
                      </div>
                    )}
                    {/* </div> */}
                    {component.type === "BODY" && (
                      <div className="card-body pt-2 pb-0 pr-4 pl-4">
                        <p className="text-start">{component.text}</p>
                      </div>
                    )}

                    {component.type === "BUTTONS" && (
                      <div>
                        {component.buttons.map((button, INDEX) => (
                          <div key={INDEX}>
                            {button.type === "url" && (
                              <div className="h-10 w-full mt-2 bg-white rounded shadow text-[#4C9EDC] flex justify-center items-center gap-2">
                                <FaArrowRightLong className="text-sm" />{" "}
                                {button.text}
                              </div>
                            )}
                            {button.type === "PHONE_NUMBER" && (
                              <div className="h-10 w-full mt-2 bg-white rounded shadow text-[#4C9EDC] flex justify-center items-center gap-2">
                                <MdOutlineCall className="text-sm" />{" "}
                                {button.text}
                              </div>
                            )}
                            {button.type === "QUICK_REPLY" && (
                              <div className="h-10 w-full mt-2 bg-white rounded shadow text-[#4C9EDC] flex justify-center items-center gap-2">
                                <HiLink className="text-sm" /> {button.text}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="w-80 card mx-auto bg-white text-black rounded-md shadow-md p-5 mt-7">
          <div className="p-1 rounded-md bg-transparent">
            <div className="w-full rounded-md overflow-hidden mb-2">
              <figure>{content}</figure>
              <div className="w-full p-2 text-sm bg-white font--mt-1">
                <p className="font-whatsapp">{isbody}</p>
                <div className="text-xs text-zinc-500 font-normal">
                  {isfooter}
                </div>
              </div>
            </div>
            {buttons.map((button, index) => (
              <div key={index}>
                {button.type === "url" && (
                  <div className="h-9 w-full mt-2 bg-white rounded shadow text-[#4C9EDC] flex justify-center items-center gap-2">
                    <FaArrowRightLong className="text-sm" /> {button.text}
                  </div>
                )}
                {button.type === "PHONE_NUMBER" && (
                  <div className="h-9 w-full mt-2 bg-white rounded shadow text-[#4C9EDC] flex justify-center items-center gap-2">
                    <MdOutlineCall className="text-sm" /> {button.text}
                  </div>
                )}
                {button.type === "QUICK_REPLY" && (
                  <div className="h-9 w-full mt-2 bg-white rounded shadow text-[#4C9EDC] flex justify-center items-center gap-2">
                    <HiLink className="text-sm" /> {button.text}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

const ChatBubble = ({ type, buttonName }) => {
  return (
    <div className="flex flex-col max-w-xs p-2 bg-[#005C58] rounded-lg">
      <div className="flex items-center bg-[#D1DFDD] rounded-t-lg px-2 py-1">
        <svg
          className="w-4 h-4 text-[#005C58] transform rotate-180"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M10 5a1 1 0 01.864.489l3 4.5A1 1 0 0113 11H7a1 1 0 01-.864-1.511l3-4.5A1 1 0 0110 5z"
            clipRule="evenodd"
          ></path>
        </svg>
        <span className="ml-2 text-sm text-[#005C58]">
          Replied to {type === "button" ? "template" : "list"}
        </span>
      </div>
      <div className="bg-[#005C58] text-white p-2 rounded-b-lg">
        <p className="text-sm">{buttonName}</p>
      </div>
    </div>
  );
};
