import React, { useState, useEffect } from "react";
import { Switch, Autocomplete, TextField } from "@mui/material";
import { Button, Container } from "@mui/material";
import { useParams } from "react-router-dom";
import { flowConfigs } from "../../../../data/flowConfigsData";
import useFlowUploud from "../../../../hooks/integrationHooks/useEComFlowSubmit";
// import useFetchStatus from "../../../../hooks/useManagetemplate"
import { useFetchStatus } from "../../../../hooks/useManagetemplate";
import { IoImageSharp, IoDocumentText, IoPlayCircle } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import toast from "react-hot-toast";

//mobile mockup icones
import { FaArrowLeft } from "react-icons/fa";
import { BsPaperclip } from "react-icons/bs";
import { BiVideo } from "react-icons/bi";
import { IoEllipsisVertical } from "react-icons/io5";
import { BiLinkExternal } from "react-icons/bi";
import { CgMailReply } from "react-icons/cg";
import { LuForward } from "react-icons/lu";
import { CgSmileMouthOpen } from "react-icons/cg";
import { MdOutlineCameraAlt } from "react-icons/md";
import { IoMdMic } from "react-icons/io";
import { MdOutlineCall } from "react-icons/md";
import useStoreDetails from "../../../../zustand/sopify/useMetaUserDetails";

// Sample templates for the dropdown

// Parameter extraction (from body template)
const parameterOptions = [
  { label: "$FirstName", value: "{{1}}" },
  { label: "$OrderAmount", value: "{{2}}" },
];

const CartFlowpage = () => {
  // const { isstatus: AcceptedTemplate, load: loadingAcceptedTemplate } =
  //   useFetchStatus("APPROVED");

  const { storeLoading, storeDetails } = useStoreDetails();

  const AprovaledShopiyTemplats = storeDetails?.shopData?.templates.filter(
    (val) => val.templateStatus === "APPROVED"
  );

  const AcceptedTemplate = AprovaledShopiyTemplats;

  console.log("AprovaledShopiyTemplats:", AprovaledShopiyTemplats);

  const templates = ["shopify_abandoned_carts", "another_template_option"];
  // this are states of templates
  const [inisialTemp, setinisialTemp] = useState(null);
  const [isPrew, setPrew] = useState("");
  const [isheader, setheader] = useState("");
  const [isbody, setbody] = useState("");
  const [isfooter, setfooter] = useState("");
  const [buttons, setbuttons] = useState([]);
  const [istitle, setTitle] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedmedia, setselectedmedia] = useState(null);
  const [istemplateid, settemplateid] = useState("");
  const [islatitude, setlatitude] = useState("");
  const [islongitude, setlongitue] = useState("");
  const [islocatioName, setlocationName] = useState("");
  const [isaddress, setaddress] = useState("");
  const [iscardData, setcardData] = useState();
  const [isobjectId, setobjectId] = useState("");

  //   for template show
  const handleTypeChange = (event, newValue) => {
    const selectedTemplateName = newValue ? newValue.name : null;

    setSelectedType(selectedTemplateName);
    setinisialTemp(selectedTemplateName);
    console.log("Selected template looks like this:", selectedType);

    if (selectedTemplateName) {
      const templateData = AcceptedTemplate.find(
        (template) => template.configData.name === selectedTemplateName
      );

      if (templateData) {
        const components = templateData.configData.components;
        let templateFormat = "";
        let headerText = "";
        let bodyText = "";
        let footer = "";
        let totalbutton = [];
        let cardData;
        let templateobjectId = templateData._id;

        for (let j = 0; j < components.length; j++) {
          const component = components[j];

          console.log("Component looks like this:", component.type);

          if (component.type === "HEADER" && component.format) {
            templateFormat = component.format;

            if (component.format === "Text") {
              headerText = component.text;
              console.log(
                "Header looks like this in map function:",
                headerText
              );
            }
          } else if (component.type === "BODY" && component.text) {
            bodyText = component.text;
          } else if (component.type === "FOOTER" && component.text) {
            footer = component.text;
          } else if (component.type === "BUTTONS" && component.buttons) {
            totalbutton = totalbutton.concat(component.buttons);
          } else if (component.type === "CAROUSEL") {
            cardData = component.cards;
          }
        }

        // Update the state with the extracted values
        setheader(templateFormat);
        setbody(bodyText);
        setfooter(footer);
        setbuttons(totalbutton);
        setTitle(headerText); // Assuming Title is set from headerText
        settemplateid(templateData.id);
        setcardData(cardData);
        setobjectId(templateobjectId);

        setPrew(selectedType);
      }
    } else {
      // Reset states if no template is selected
      setheader("");
      setbody("");
      setfooter("");
      setbuttons([]);
      setTitle("");
      settemplateid("");
      setcardData();
    }
  };

  const formatText = (text) => {
    return text
      .replace(/\*(.*?)\*/g, "<b>$1</b>") // Bold
      .replace(/_(.*?)_/g, "<i>$1</i>") // Italic
      .replace(/~(.*?)~/g, "<s>$1</s>"); // Strikethrough
  };

  const handleMouseLeave = (event, option) => {
    setSelectedType(null);
  };

  let content;
  if (isheader.toUpperCase() === "IMAGE") {
    content = <IoImageSharp className="h-40 w-52 bg-orange-300 rounded-md" />;
  } else if (isheader.toUpperCase() === "VIDEO") {
    content = (
      <div className="text-4xl text-blue-600 h-40 w-52 rounded-md">
        <IoPlayCircle className="h-40 w-52" />
      </div>
    );
  } else if (isheader.toUpperCase() === "DOCUMENT") {
    content = <IoDocumentText className="h-40 w-52 text-red-400 " />;
  } else if (isheader.toUpperCase() === "LOCATION") {
    content = (
      <div className="text-4xl text-red-600 h-40 w-52 rounded-md flex items-center justify-center">
        <FaLocationDot className=" text-2xl h-24 w-52 text-red-600" />
      </div>
    );
  } else if (!isheader) {
    content = <div></div>;
  }

  const [parentObjectsArray, setParentObjectsArray] = useState([]);
  const [hoveredOption, setHoveredOption] = useState(null);

  const handleObjectsArrayChange = (newObjectsArray) => {
    setParentObjectsArray(newObjectsArray);
    console.log("Updated objectsArray in parent:", newObjectsArray);
  };
  console.log("parents parameterlooks like this :", parentObjectsArray);

  let bodyparamter = [];
  if (Array.isArray(parentObjectsArray)) {
    bodyparamter = parentObjectsArray.map((parameter) =>
      parameter.parameterVal.trim()
    );
    console.log("strinded value looks like this :", bodyparamter);
    // return bodyparamter;
  }

  const { loading, FlowUploud } = useFlowUploud();

  const { title, subtitle } = useParams();

  const cartData = flowConfigs[title][subtitle];
  console.log(subtitle, ":", cartData);

  const isFlowrun = storeDetails?.shopData?.[`${subtitle}`]?.isFlowRun;
  const selectedTemp = storeDetails?.shopData?.[subtitle]
    ? AcceptedTemplate.find(
        (val) => val._id === storeDetails.shopData[subtitle].selectedTemplate
      )?.configData
    : null;

  let selectedParameters = storeDetails?.shopData?.[`${subtitle}`]?.parameters;

  // Check if selectedParameters is defined and is an array
  selectedParameters =
    selectedParameters?.map((item) => {
      try {
        // Check if the item is a string and parse it, else return it as is
        return typeof item === "string" ? JSON.parse(item) : item;
      } catch (error) {
        console.error("Error parsing JSON:", error);
        return item; // Return the item as is if it cannot be parsed
      }
    }) || [];

  const timingFromBack = storeDetails?.shopData?.[`${subtitle}`]?.timing;
  console.log("selectedTemp:", timingFromBack);

  const [showtemplate, setTemplate] = useState([]);

  useEffect(() => {
    setTemplate(selectedTemp);
    setinisialTemp(selectedTemp?.name);
    setPrew(showtemplate);
  }, [selectedTemp, showtemplate]);

  console.log("isFlowrun:", isFlowrun);
  console.log("selectedTemplate:", storeDetails?.shopData?.[`${subtitle}`]);

  useEffect(() => {
    if (showtemplate?.components) {
      const components = showtemplate.components;
      let templateFormat = "";
      let headerText = "";
      let bodyText = "";
      let footer = "";
      let totalbutton = [];

      for (let j = 0; j < components.length; j++) {
        const component = components[j];

        console.log("Component looks like this:", component);

        if (component.type === "HEADER" && component.format) {
          templateFormat = component.format;

          if (component.format === "Text") {
            headerText = component.text;
            console.log("Header looks like this in map function:", headerText);
          }
        } else if (component.type === "BODY" && component.text) {
          bodyText = component.text;
        } else if (component.type === "FOOTER" && component.text) {
          footer = component.text;
        } else if (component.type === "BUTTONS" && component.buttons) {
          totalbutton = totalbutton.concat(component.buttons);
        }
      }

      // Update the state with the extracted values
      setheader(templateFormat);
      setbody(bodyText);
      setfooter(footer);
      setbuttons(totalbutton);
      setTitle(headerText); // Assuming Title is set from headerText
    }
  }, [showtemplate]);
  console.log(
    "selected  template in usestate looks likes this :",
    showtemplate
  );

  const [isCartEnabled, setCartEnabled] = useState(
    isFlowrun || cartData.CartSettings
  );
  const [selectedTime, setSelectedTime] = useState(timingFromBack || null);
  const [selectedTemplate, setSelectedTemplate] = useState(templates[0]);
  const [parameters, setParameters] = useState();

  useEffect(() => {
    if (isFlowrun !== undefined) {
      setCartEnabled(isFlowrun);
      setSelectedTime(timingFromBack);
    }
  }, [isFlowrun, timingFromBack]);

  console.log("data for template setup:", isCartEnabled);
  console.log("data for template setup:", istemplateid);
  console.log("data for template setup:", selectedTime);

  const hadleUploadData = async () => {
    if (!loading) {
      const updateData = {
        isFlowRun: isCartEnabled,
        timing: selectedTime || null,
        selectedTemplate: isobjectId, // we will send template ID
        parameters: parentObjectsArray,
      };

      console.log(
        "data in shopify store template setup looks likes :",
        isCartEnabled
      );
      console.log(
        "data in shopify store template setup looks likes :",
        selectedTime
      );
      // if (!data.Template) {
      //   toast.error("Template name required!");
      //   return;
      // } else if (!data.Parameters) {
      //   toast.error("Parameters required!");
      //   return;
      // }

      await FlowUploud(updateData, subtitle);
    }
  };

  const parametersOPT = ["$FirstName", "$OrderAmount"];
  return (
    <>
      {storeLoading && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-green-500"></div>
        </div>
      )}
      <Container className="bg-gray-50 p-4 rounded-lg shadow-lg mt-4">
        {/* Abandoned Cart Header */}
        {/* <div className="flex justify-between items-center mb-4 sticky top-0 bg-white z-10 p-4 border-b border-gray-200">
        <h1 className="text-xl font-semibold">Abandoned Cart Flow</h1>
        <div className="flex items-center space-x-2">
          <img src="https://via.placeholder.com/40" alt="Store Logo" className="rounded-full" />
          <span className="text-lg font-medium">Store Name: Princegrocery</span>
        </div>
      </div> */}

        {/* Cart 1 */}
        <div className="mb-6  z-10 p-4 bg-white border-b border-gray-200 flex justify-between">
          <div>
            <h2 className="text-lg font-semibold">{cartData.heading}</h2>
            <p className="text-gray-500 text-sm">{cartData.subHeading}</p>
          </div>
          <Switch
            checked={isCartEnabled}
            onChange={() => setCartEnabled(!isCartEnabled)}
            color="success"
          />
        </div>

        {/* Configure Flow */}
        <div className="bg-white p-6 h-[70vh] overflow-auto rounded-lg shadow-md">
          <h3 className="text-lg font-semibold mb-4">Configure This Flow</h3>

          <div className="flex justify-between gap-5">
            <div className="w-3/6">
              {/* Timing Autocomplete */}
              {cartData.timingOptions && (
                <div className="mb-6">
                  <h4 className="font-medium">Timing</h4>
                  <p className="text-sm text-gray-500 mb-2">
                    Select the time interval to automate the first abandoned
                    cart message
                  </p>
                  <Autocomplete
                    options={cartData.timingOptions}
                    value={selectedTime}
                    onChange={(e, newValue) => setSelectedTime(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} label="Timing" />
                    )}
                    className="w-full"
                  />
                </div>
              )}

              {/* Template Selection Autocomplete */}
              <div className="mb-6">
                <h4 className="font-medium">Choose Template</h4>
                <p className="text-sm text-gray-500 mb-2">
                  Select one from your WhatsApp-approved template messages
                </p>

                <Autocomplete
                  disablePortal
                  id="TemplateLanguage"
                  value={inisialTemp ? { name: inisialTemp } : null} // Set default value
                  options={
                    Array.isArray(AcceptedTemplate) &&
                    AcceptedTemplate.map((data) => ({
                      name: data.configData.name,
                    }))
                  }
                  getOptionLabel={(option) => option.name}
                  sx={{
                    width: "100%",
                    marginTop: "15px",
                    background: "#F0F0F0",
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .css-z8b3ht-MuiFormLabel-root-MuiInputLabel-root": {
                      display: "none",
                    },
                    "& .MuiAutocomplete-input": {
                      padding: "7.5px 4px 7.5px 13px",
                    },
                    "& .MuiFormLabel-root": {
                      color: "#A9A9A9",
                      fontSize: "medium",
                    },
                  }}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      onMouseEnter={(event) => handleTypeChange(event, option)} // Mouse move event
                      onMouseLeave={(event) => handleMouseLeave(event, option)}
                      style={{
                        backgroundColor:
                          hoveredOption === option ? "#e0e0e0" : "white", // Highlight on hover
                      }}
                    >
                      {option.name}
                    </li>
                  )}
                  onChange={handleTypeChange} // Keep original onChange for selection
                  renderInput={(params) => (
                    <TextField {...params} label="Choose template" />
                  )}
                />
              </div>

              {/*  Template Parameters */}
              <div className="mb-6">
                <h4 className="font-medium  mb-3"> Template Parameters</h4>

                <ParamField
                  isbody={isbody}
                  onObjectsArrayChange={handleObjectsArrayChange}
                  selectedParameters={selectedParameters}
                  showtemplate={showtemplate}
                />
              </div>
            </div>

            {/* WhatsApp Preview */}
            <div className="w-3/6 border p-4 rounded-md bg-gray-100">
              <h4 className="font-medium">Preview Message</h4>
              {!isPrew ? (
                <div className="flex justify-center  items-center   overflow-hidden mb-2">
                  {/* <span>select your template</span> */}
                  <div className="container  flex justify-center">
                    <div className="mockup-phone sm:w-[285px]  w-[285px]  lg:w-[285px]  ">
                      <div className="camera "></div>
                      <div className="display bg-white min-h-[450px] relative ">
                        <div className="flex flex-col min-h-[500px]  bg-gray-100">
                          <div className="bg-teal-700 text-white p-2  min-h-[45px] flex items-center">
                            <FaArrowLeft className="mr-2 text-sm text-white mt-4" />

                            <div className="w-8 h-8 bg-yellow-400 rounded-full flex items-center justify-center mr-2 mt-4">
                              <span className="text-black font-bold">T</span>
                            </div>
                            <div className="flex-grow">
                              <div className="font-semibold mt-3">
                                TheBotmode
                              </div>
                              <div className="text-xs  font-normal ">
                                online
                              </div>
                            </div>
                            <div className="flex items-center h-[45px] mt-3 ">
                              <BiVideo className="mx-2 text-lg text-white" />
                              <MdOutlineCall className="mx-1 text-lg text-white" />
                              <IoEllipsisVertical className="mx-1 text-lg text-white" />
                            </div>
                          </div>

                          {/* Chat area */}
                          <div className="flex-grow bg-gray-200 p-4 relative">
                            <img
                              src="https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png"
                              className="h-full w-full object-cover absolute inset-0"
                              alt="whatsapp background"
                            />
                            <div className="  h-[20px] w-[45px]  bg-slate-100 rounded-sm border-1   absolute top-1 left-[40%]  flex items-center justify-center z-[3] bg-opacity-55">
                              <span className="text-xs text-center  font-normal  text-[#54656F]">
                                Today
                              </span>
                            </div>
                            <p className="text-gray-500 absolute  top-14 left-[22%] text-sm">
                              select your template
                            </p>
                          </div>

                          {/* Input area */}
                          <div className=" w-full p-2 flex items-center mb-2 absolute bottom-1 left-0">
                            <div className="flex-grow bg-white rounded-full w-[80%]  py-2 flex items-center">
                              <CgSmileMouthOpen className="text-gray-600 mx-2 text-xl " />
                              <input
                                type="text"
                                placeholder="Type a message"
                                className="w-[60%] outline-none bg-white text-gray-500"
                                readOnly
                                // value="type something "
                                // onChange={(e) => setInputValue(e.target.value)}
                              />
                              {/* <Paperclip className="text-gray-600 mx-2" /> */}
                              <BsPaperclip className="text-gray-600  mx-2 text-lg" />

                              {/* <div className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center"> */}
                              {/* <img src="/api/placeholder/32/32" alt="Camera icon" className="w-5 h-5" /> */}
                              <MdOutlineCameraAlt className="text-gray-600  mx-2 text-lg" />
                              {/* </div> */}
                            </div>
                            <div className="w-8 h-8 bg-teal-700 rounded-full flex items-center justify-center ml-1 ">
                              {/* <Mic className="text-white" /> */}
                              <IoMdMic className="text-white text-lg " />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : iscardData ? (
                <div></div>
              ) : (
                <div>
                  <div className="container  flex justify-center">
                    <div className="mockup-phone sm:w-[285px]  w-[285px]  lg:w-[285px]  ">
                      <div className="camera "></div>
                      <div className="display bg-white min-h-[450px] relative ">
                        <div className="flex flex-col min-h-[500px]  bg-gray-100">
                          <div className="bg-teal-700 text-white p-2  min-h-[45px] flex items-center">
                            <FaArrowLeft className="mr-2 text-sm text-white mt-4" />

                            <div className="w-8 h-8 bg-yellow-400 rounded-full flex items-center justify-center mr-2 mt-4">
                              <span className="text-black font-bold">T</span>
                            </div>
                            <div className="flex-grow">
                              <div className="font-semibold mt-3">
                                TheBotmode
                              </div>
                              <div className="text-xs  font-normal ">
                                online
                              </div>
                            </div>
                            <div className="flex items-center h-[45px] mt-3 ">
                              <BiVideo className="mx-2 text-lg text-white" />
                              <MdOutlineCall className="mx-1 text-lg text-white" />
                              <IoEllipsisVertical className="mx-1 text-lg text-white" />
                            </div>
                          </div>

                          {/* Chat area */}
                          <div className="flex-grow bg-gray-200 p-4 relative">
                            <img
                              src="https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png"
                              className="h-full w-full object-cover absolute inset-0"
                              alt="whatsapp background"
                            />
                            <div className="  h-[20px] w-[45px]  bg-slate-100 rounded-sm border-1   absolute top-1 left-[40%]  flex items-center justify-center z-[3] bg-opacity-55">
                              <span className="text-xs text-center  font-normal  text-[#54656F]">
                                Today
                              </span>
                            </div>
                            <div className="absolute rounded-t-md top-15 left-3 w-10/12 mx-auto max-h-[calc(100%-96px)] overflow-y-auto custom-scroll ">
                              <div className="bg-white shadow p-2 text-center overflow-hidden">
                                {/* {selectedType !== "None" && selectedType !== "Text" && ( */}
                                <div className="w-auto  bg-[#F8F8F8] rounded-md flex gap-5  justify-center items-center ">
                                  {selectedmedia?.url.length > 0 ? (
                                    selectedmedia?.mediaType === "image" ? (
                                      <figure>
                                        <img
                                          src={selectedmedia?.url}
                                          alt="loading image"
                                          className="h-40 w-96 object-cover  rounded-md cursor-pointer"
                                        />
                                      </figure>
                                    ) : selectedmedia?.mediaType === "video" ? (
                                      <figure>
                                        <video
                                          src={selectedmedia?.url}
                                          controls
                                          className="h-40 w-80 object-cover cursor-pointer"
                                        />
                                      </figure>
                                    ) : selectedmedia?.mediaType ===
                                      "document" ? (
                                      <div className="w-96 h-40">
                                        <iframe
                                          src={selectedmedia?.url}
                                          className="w-full h-full"
                                          title="PDF Preview"
                                          frameBorder="0"
                                          scrolling="auto"
                                        ></iframe>
                                      </div>
                                    ) : null
                                  ) : (
                                    <figure className=" ">{content}</figure>
                                  )}
                                </div>
                                {/* )} */}
                                <div className="text-sm  font-whatsapp-bold text-start mt-3">
                                  {istitle}
                                </div>
                                <div className="font-normal text-sm mt-3 text-start overflow-hidden">
                                  <div
                                    className="overflow-hidden  "
                                    dangerouslySetInnerHTML={{
                                      __html: formatText(isbody),
                                    }}
                                  />
                                </div>
                                <div className="text-xs font-normal text-start text-blue-600 mt-4">
                                  {isfooter}
                                </div>
                              </div>

                              {buttons.map((button, index) => (
                                <div key={index} className="">
                                  {button.type === "PHONE_NUMBER" && (
                                    <div className="templatebuttons flex gap-1 items-center justify-center border-blue border-1">
                                      <MdOutlineCall className="text-blue-600 text-sm" />
                                      {button.text}
                                    </div>
                                  )}
                                  {button.type === "url" && (
                                    <div className="templatebuttons flex gap-1 items-center justify-center border-blue border-1">
                                      <BiLinkExternal className="text-blue-600 text-sm" />{" "}
                                      {button.text}
                                    </div>
                                  )}
                                  {button.type === "QUICK_REPLY" && (
                                    <div className="templatebuttons rounded-b-md flex gap-1 items-center justify-center border-blue border-1">
                                      <CgMailReply className="text-blue-600 text-sm" />
                                      {button.text}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                            <div className="h-6 w-6 rounded-full bg-gray-500 bg-opacity-30 absolute top-[35%] ml-1 right-1.5 flex items-center justify-center">
                              <LuForward className="text-white text-sm" />
                            </div>
                          </div>

                          {/* Input area */}
                          <div className=" w-full p-2 flex items-center mb-2 absolute bottom-1 left-0">
                            <div className="flex-grow bg-white rounded-full w-[80%]  py-2 flex items-center">
                              <CgSmileMouthOpen className="text-gray-600 mx-2 text-xl " />
                              <input
                                type="text"
                                placeholder="Type a message"
                                className="w-[60%] outline-none bg-white "
                                readOnly
                                // value={inputValue}
                                // onChange={(e) => setInputValue(e.target.value)}
                              />
                              {/* <Paperclip className="text-gray-600 mx-2" /> */}
                              <BsPaperclip className="text-gray-600  mx-2 text-lg" />

                              {/* <div className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center"> */}
                              {/* <img src="/api/placeholder/32/32" alt="Camera icon" className="w-5 h-5" /> */}
                              <MdOutlineCameraAlt className="text-gray-600  mx-2 text-lg" />
                              {/* </div> */}
                            </div>
                            <div className="w-8 h-8 bg-teal-700 rounded-full flex items-center justify-center ml-1 ">
                              {/* <Mic className="text-white" /> */}
                              <IoMdMic className="text-white text-lg " />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="btn btn-sm bg-[#0A474C] text-white border-none rounded-md hover:bg-[#173b3e] text-end mr-0 float-right mt-5 w-20"
          onClick={hadleUploadData}
        >
          {loading ? <span className="loading loading-dots"></span> : "Submit"}
        </div>
      </Container>
    </>
  );
};

function ParamField({
  isbody,
  onObjectsArrayChange,
  selectedParameters,
  showtemplate,
}) {
  const [inputValue, setInputValue] = useState(isbody);

  useEffect(() => {
    setInputValue(isbody);
    handleChange(isbody);
  }, [isbody]);

  useEffect(() => {
    setObjectsArray(selectedParameters);
  }, [showtemplate]);
  console.log("Template body text:", inputValue);
  console.log("Template selectedParameters:", selectedParameters);

  const [objectsArray, setObjectsArray] = useState(selectedParameters);

  console.log("parameter looks like this :", objectsArray);

  const handleChange = (inputValue) => {
    // const value = e.target.value;

    const value = inputValue;

    const regex = /{{(\d+)}}/g;
    let match;
    const idsFound = new Set();

    // Extract existing IDs
    while ((match = regex.exec(value)) !== null) {
      idsFound.add(parseInt(match[1], 10));
    }

    // Sort the IDs and create new sequential IDs
    const sortedIds = Array.from(idsFound).sort((a, b) => a - b);
    const idMapping = new Map();
    let currentId = 1;
    sortedIds.forEach((originalId) => {
      idMapping.set(originalId, currentId++);
    });

    // Replace the original IDs with the new sequential IDs
    let modifiedValue = value.replace(
      regex,
      (match, p1) => `{{${idMapping.get(parseInt(p1, 10))}}}`
    );

    // Create a lookup map from objectsArray
    const lookupMap = new Map(
      objectsArray.map((item) => [item.id, item.parameterVal])
    );

    // Update the new objects array with sequential IDs
    const newObjectsArray = Array.from(idMapping.values()).map((id) => ({
      id,
      parameterVal: lookupMap.get(id) || "", // Use existing value if it exists, otherwise empty string
    }));

    setInputValue(modifiedValue);
    setObjectsArray(newObjectsArray);

    console.log("newObjectsArray:", objectsArray);
  };

  useEffect(() => {
    handleChange(inputValue);
  }, [inputValue]);

  useEffect(() => {
    onObjectsArrayChange(objectsArray);
  }, [objectsArray, onObjectsArrayChange]);

  const handleParameterValChange = (event, id, newValue) => {
    setObjectsArray(
      // New Method
      objectsArray.map((item) =>
        item.id === id ? { ...item, parameterVal: newValue.label } : item
      )

      // old method
      // objectsArray.map((item) => {
      //   if (item.id === id) {
      //     return { ...item, parameterVal: newValue };
      //   } else {
      //     return item;
      //   }
      // })
    );
  };

  // const TheadingData = [
  //   { label: "name", id: 1 },
  //   { label: "mobile_Number", id: 2 },
  // ];

  const TheadingData = [
    { label: "$FirstName", id: 2 },
    { label: "$LastName", id: 3 },
    { label: "$MobileNumber", id: 4 },
    { label: "$fullName", id: 5 },
    { label: "$OrderId", id: 6 },
    { label: "$ZipCode", id: 7 },
    { label: "$Currency", id: 8 },
    { label: "$LineItems", id: 9 },
    { label: "$OrderName", id: 10 },
    { label: "$CheckoutId", id: 11 },
    { label: "$InvoiceUrl", id: 12 },
    { label: "$OrderNumber", id: 13 },
    { label: "$OrderAmount", id: 14 },
    { label: "$TrackingNumber", id: 15 },
    { label: "$OrderStatusUrl", id: 16 },
    { label: "$OrderCreatedOn", id: 17 },
    { label: "$PaymentGateway", id: 18 },
    { label: "$ShippingAddress", id: 19 },
    { label: "$FullTrackingUrl", id: 20 },
    // { label: "$PartialInvoiceUrl", id: 21 },
    // { label: "$PartialTrackingUrl", id: 22 },
    // { label: "$PartialOrderStatusUrl", id: 23 },
    // { label: "$PartialPrefilledCartUrl", id: 24 },
    // { label: "$FullAbandonedCheckoutUrl", id: 25 },
    // { label: "$PartialAbandonedCheckoutUrl", id: 26 },
  ];

  return (
    <>
      {/* <TextComponent
        heading="Parameters"
        // body="You can personalize messages with - $FirstName, $Name, $MobileNumber etc."
        body1="You can personalize messages with - $FirstName, $Name, $MobileNumber etc."
      /> */}
      {objectsArray.length > 0 && (
        <div className="mt-5">
          {objectsArray.map((item) => (
            <div className="flex justify-between items-center" key={item.id}>
              <div className="text-lg w-1/6 font-normal mt-6 pe-3">{`{{${item.id}}}`}</div>
              {/* <input
                value={item.parameterVal}
                onChange={(event) => {
                  handleParameterValChange(event, item.id);
                }}
                type="text"
                placeholder="Enter parameter value"
                className="input input-xs input-bordered w-5/6 h-auto p-2 bg-[#F0F0F0] text-black mt-2 placeholder:font-medium font-normal"
              /> */}

              <div className="w-5/6">
                <Autocomplete
                  disablePortal
                  id="TemplateLanguage"
                  options={TheadingData}
                  getOptionLabel={(option) => option.label}
                  value={
                    TheadingData.find(
                      (data) => data.label === item.parameterVal
                    ) || null
                  } // Set the default value from objectsArray
                  sx={{
                    width: "100%",
                    marginTop: "30px",
                    background: "#F0F0F0",
                    height: "35px",
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .css-z8b3ht-MuiFormLabel-root-MuiInputLabel-root": {
                      // display: "none",
                    },
                    "& .css-8ujita-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input":
                      {
                        padding: "0px !important",
                      },
                    "& .MuiInputLabel-root": {
                      color: "#A9A9A9",
                      fontSize: "medium",
                      marginTop: "-8px !important",
                    },
                    "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                      padding: "0px !important",
                    },
                  }}
                  onChange={(event, newValue) => {
                    handleParameterValChange(event, item.id, newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Choose Tag" />
                  )}
                />
              </div>
            </div>
          ))}
        </div>
      )}

      {/* <button className="btn btn-success" onClick={handleChange}>
        Click
      </button> */}
    </>
  );
}

export default CartFlowpage;
