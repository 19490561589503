import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import useAllTemplateZus from "../../zustand/useAllTemplate";
import { useNavigate } from "react-router-dom";
import useStoreDetails from "../../zustand/sopify/useMetaUserDetails";

const useShopifyStoreConnection = () => {
  const [isloader, setLoader] = useState(false);
  const { setStoreDetails } = useStoreDetails();

  const handleshopifystore = async ({ storeUrl, apiToken, apisecreateKey }) => {
    // console.log("Data from frontend in hook:", { storeUrl, apiToken, apisecreateKey });

    try {
      setLoader(true);
      const res = await axios.post("/api/shopify/connectShopify", {
        storeUrl,
        apiToken,
        apisecreateKey,
      });

      console.log(res.data);

      setStoreDetails(res.data);
      toast.success("Your store is Connected!");
    } catch (error) {
      const errorMessage =
        error.response?.data?.error?.error_user_msg ||
        error.response?.data?.error ||
        error.message ||
        "An error occurred. Please try again.";

      toast.error(errorMessage);
    } finally {
      setLoader(false);
    }
  };

  return { isloader, handleshopifystore };
};

const useShopifytemplatesetup = () => {
  const [istemplateresponse, setTemplateresponse] = useState(null);
  const [loadTemplate, setLoadTemplate] = useState(false);

  const { allTemplate, setAllTemplate } = useAllTemplateZus();

  const navigate = useNavigate();

  const templateCreate = async ({
    templateName,
    headertext,
    languageCode,
    category,
    format,
    headerText,
    templateBody,
    templatefooter,
    body_text,
    UrlButtons,
    Phone_Number,
    QUICKREPLYtext,
    cardData,
    cardformat,
  }) => {
    try {
      setLoadTemplate(true);
      let totalbuttoncount =
        UrlButtons.length + Phone_Number.length + Phone_Number.length;
      console.log("format looks likes :", format);
      if (format === "CAROUSEL" && totalbuttoncount < 0) {
        toast.error("atleast one button is required in carousel template ");
        return;
      }
      const response = await axios.post("/api/shopify/Shopifytemplates", {
        templateName,
        headertext,
        languageCode,
        category,
        format,
        templateBody,
        headerText,
        templatefooter,
        body_text,
        UrlButtons,
        Phone_Number,
        QUICKREPLYtext,
        cardData,
        cardformat,
      });
      const data = await response.data;

      console.log("reposne data is:", data);
      console.log("reposne data is:", data.message);

      //add new template in template list
      const newtemplate = data.template;
      setAllTemplate([...allTemplate, newtemplate]);

      toast.success(data.message); // Show success message

      // redirect to template page when the req res will success!
      // if (newtemplate.templateStatus === "REJECTED") {
      //   navigate("/template/message/action");
      // } else if (newtemplate.templateStatus === "PENDING") {
      //   navigate("/template/message/pending");
      // } else if (
      //   newtemplate.templateStatus === "APPROVED" &&
      //   newtemplate.configData.category === "MARKETING"
      // ) {
      //   navigate("/template/message/");
      // } else if (
      //   newtemplate.templateStatus === "APPROVED" &&
      //   newtemplate.configData.category === "UTILITY"
      // ) {
      //   navigate("/template/message/Utility");
      // } else if (
      //   newtemplate.templateStatus === "APPROVED" &&
      //   newtemplate.configData.category === "AUTHENTICATION"
      // ) {
      //   navigate("/template/message/Authentication");
      // }
    } catch (error) {
      // toast.error(error.response?.data?.error || error.message); // Show error message
      const errorMessage =
        error.response?.data?.error?.error_user_msg ||
        error.response?.data?.error ||
        error.message;

      toast.error(errorMessage); // Show detailed error message
    } finally {
      setLoadTemplate(false);
    }
  };

  return { loadTemplate, istemplateresponse, templateCreate };
};

const useGetStoreDetails = () => {
  const { storeLoading, setStoreLoading, storeDetails, setStoreDetails } =
    useStoreDetails();

  const getStoreData = async () => {
    try {
      setStoreLoading(true);
      const res = await axios.get("/api/shopify/getStoreDetails");

      console.log("useGetStoreDetails", res.data);

      setStoreDetails(res.data);
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setStoreLoading(false);
    }
  };

  useEffect(() => {
    getStoreData();
  }, []);
};

const useDisconnectStore = () => {
  const [Disconnectloading, setDisconnectloading] = useState(false);
  const { setStoreDetails } = useStoreDetails();

  const disconnectStore = async (id, templateIDsandNames) => {
    const params = { id, templateIDsandNames };
    try {
      setDisconnectloading(true);
      const res = await axios.delete("/api/shopify/disconnectStore", {
        params,
      });

      console.log(res.data);

      setStoreDetails(res.data);
      toast.success("Your store is disconnected!");
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setDisconnectloading(false);
    }
  };

  return { Disconnectloading, disconnectStore };
};

export {
  useShopifyStoreConnection,
  useShopifytemplatesetup,
  useGetStoreDetails,
  useDisconnectStore,
};
