// import React, { useState } from "react";
// import { BsSend } from "react-icons/bs";
// import useSendMessages from "../../hooks/useSendMessages";

// const MessageInput = () => {
//   const [input, setInput] = useState("");

//   const { loading, sendMessage } = useSendMessages();

//   const hadleOnSubmit = async (e) => {
//     e.preventDefault();
//     await sendMessage(input);
//     setInput("");
//   };

//   return (
//     <form className="px-4 my-3" onSubmit={hadleOnSubmit}>
//       <div className="w-full relative">
//         <input
//           type="text"
//           className="border text-sm rounded-lg block p-2.5 bg-gray-700 border-gray-600 text-white w-full"
//           placeholder="Send a message"
//           value={input}
//           onChange={(e) => {
//             setInput(e.target.value);
//           }}
//         />
//         <button
//           type="submit"
//           className="btn bg-transparent border-none hover:bg-transparent hover:text-blue-500 text-xl mt-[-2px] absolute inset-y-0 end-0 flex items-center pe-3"
//         >
//           {loading ? (
//             <div className="loading loading-spinner"></div>
//           ) : (
//             <BsSend />
//           )}
//         </button>
//       </div>
//     </form>
//   );
// };

// export default MessageInput;

//STARTER CODE OF THIS FILE
import React, { useState } from "react";

import { Button, IconButton, styled } from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import BoltIcon from "@mui/icons-material/Bolt";
import SendIcon from "@mui/icons-material/Send";
import useSendMessage from "../../../hooks/useSendMessage";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const MessageInput = () => {
  const [input, setInput] = useState("");
  const { loading, sendMessage } = useSendMessage();

  const hadleSubmit = async (e) => {
    e.preventDefault();
    await sendMessage(input);
    setInput("");
  };

  return (
    <>
      <form className="px-4 z-50">
        <div className="w-full bg-white rounded-md mb-2 relative">
          <textarea
            type="text"
            className="border-none outline-none  text-sm rounded-lg block p-2.5 bg-white w-full mb-4 "
            placeholder="Send a message"
            value={input}
            onChange={(e) => {
              setInput(e.target.value);
            }}
          />
          <div className="flex justify-between items-center px-2 pb-2">
            <div className="flex">
              <IconButton aria-label="bold">
                <FormatBoldIcon />
              </IconButton>
              <IconButton aria-label="italic">
                <FormatItalicIcon />
              </IconButton>
              <IconButton aria-label="emoji">
                <SentimentSatisfiedAltIcon />
              </IconButton>
              <IconButton
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
              >
                <VisuallyHiddenInput type="file" />
                <AttachFileIcon />
              </IconButton>
              <IconButton aria-label="templat">
                <BoltIcon />
              </IconButton>
            </div>
            <div>
              <Button
                variant="contained"
                sx={{ background: "#0A474C" }}
                endIcon={<SendIcon />}
                className="hover:bg-[#0A474C]"
                onClick={(e) => {
                  hadleSubmit(e);
                }}
                disabled={loading}
                type="submit"
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default MessageInput;
