import { useEffect } from "react";
import io from "socket.io-client";
import useZusConversation from "../zustand/useZusConversation";

const DOMAIN_WHATSAPP_API_UR = "https://app.thebotmode.com";

const socket = io(`${DOMAIN_WHATSAPP_API_UR}`);

const useListenMessages = () => {
  const { messages, setMessages, selectedConversation } = useZusConversation();

  useEffect(() => {
    socket.on("newMessage", (newMessage) => {
      console.log("message from socket:", newMessage);
      console.log("selectedConversation:", selectedConversation);

      if (selectedConversation?._id === newMessage.senderId) {
        setMessages([...messages, newMessage]);
      }
    });

    return () => socket?.off("newMessage");
  }, [messages, selectedConversation]);
};

export default useListenMessages;
