import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";

const useOneContactCr = () => {
  const [loading, setLoading] = useState(false);

  const CreateContact = async ({ name, tags, source }, number) => {
    const success = handleInputError({ name, number, tags, source });

    if (!success) return;

    setLoading(true);
    try {
      const res = axios.post("/api/contact/single/upload", {
        name,
        source,
        mobile_Number: number,
        tags,
      });
      const data = await res;

      toast.success(data.data.message);
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading, CreateContact };
};

export default useOneContactCr;

const handleInputError = ({ name, number }) => {
  if (!name || !number) {
    toast.error("Please fill the all fields!");
    return false;
  }

  const indianPhoneNumberRegex = /^(\+91[\-\s]?)?[6-9]\d{9}$/;

  if (!indianPhoneNumberRegex.test(number)) {
    toast.error(
      "Invalid phone number format! (Must be +CountryCode followed by 10 digits)"
    );
    return false;
  }

  return true;
};
