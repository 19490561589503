import React, { useEffect, useState, useMemo } from "react";
import { TextComponent } from "../components/template/CreateTemplate";
import { Autocomplete, TextField } from "@mui/material";
import { RiWhatsappFill } from "react-icons/ri";
import { IoDocumentText, IoImageSharp, IoPlayCircle } from "react-icons/io5";
import { FaArrowRightLong, FaArrowUpRightFromSquare } from "react-icons/fa6";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { IoIosCheckmarkCircle } from "react-icons/io";

import { BsPaperclip } from "react-icons/bs";
import { FaArrowLeft } from "react-icons/fa";
import { BiVideo } from "react-icons/bi";
import { IoEllipsisVertical } from "react-icons/io5";
import { BiLinkExternal } from "react-icons/bi";
import { CgMailReply } from "react-icons/cg";
import { LuForward } from "react-icons/lu";
import { CgSmileMouthOpen } from "react-icons/cg";
import { MdOutlineCameraAlt } from "react-icons/md";
import { IoMdMic } from "react-icons/io";
import { MdVisibility } from "react-icons/md";
import {
  MdCheckCircle,
  MdDeleteForever,
  MdOutlineCall,
  MdOutlineCloudUpload,
  MdPermMedia,
} from "react-icons/md";
import { useFetchStatus, useSingleMessage } from "../hooks/useManagetemplate";
import { HiLink } from "react-icons/hi2";
import { MdDownload } from "react-icons/md";
import {
  useFetchMedia,
  usePostmedia,
  useDeleteMedia,
} from "../hooks/usemediadata";
import toast from "react-hot-toast";
import { FaLocationDot } from "react-icons/fa6";
const TheadingData = [
  { label: "None", id: 1 },
  { label: "Text", id: 2 },
  { label: "Image", id: 3 },
  { label: "Video", id: 4 },
  { label: "Document", id: 5 },
  { label: "Location", id: 6 },
];

const BulkMessageBox = ({
  mobileNumbers,
  selectedList,
  data,
  onsend,
  trigger,
}) => {
  const { isstatus: AcceptedTemplate, load: loadingAcceptedTemplate } =
    useFetchStatus("APPROVED");
  const [isheader, setheader] = useState("");
  const [isbody, setbody] = useState("");
  const [isfooter, setfooter] = useState("");
  const [buttons, setbuttons] = useState([]);
  const [istitle, setTitle] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedmedia, setselectedmedia] = useState(null);
  const [istemplateid, settemplateid] = useState("");
  const [islatitude, setlatitude] = useState("");
  const [islongitude, setlongitue] = useState("");
  const [islocatioName, setlocationName] = useState("");
  const [isaddress, setaddress] = useState("");
  const [iscardData, setcardData] = useState();
  console.log("location value looks likes :", islatitude);
  console.log("location value looks likes :", islongitude);
  console.log("location value looks likes :", islocatioName);
  console.log("location value looks likes :", isaddress);

  //   for template show
  const handleTypeChange = (event, newValue) => {
    const selectedTemplateName = newValue ? newValue.name : null;
    setSelectedType(selectedTemplateName);
    console.log("Selected template looks like this:", selectedType);

    if (selectedTemplateName) {
      const templateData = AcceptedTemplate.find(
        (template) => template.configData.name === selectedTemplateName
      );

      if (templateData) {
        const components = templateData.configData.components;
        let templateFormat = "";
        let headerText = "";
        let bodyText = "";
        let footer = "";
        let totalbutton = [];
        let cardData;

        for (let j = 0; j < components.length; j++) {
          const component = components[j];

          console.log("Component looks like this:", component.type);

          if (component.type === "HEADER" && component.format) {
            templateFormat = component.format;

            if (component.format === "Text") {
              headerText = component.text;
              console.log(
                "Header looks like this in map function:",
                headerText
              );
            }
          } else if (component.type === "BODY" && component.text) {
            bodyText = component.text;
          } else if (component.type === "FOOTER" && component.text) {
            footer = component.text;
          } else if (component.type === "BUTTONS" && component.buttons) {
            totalbutton = totalbutton.concat(component.buttons);
          } else if (component.type === "CAROUSEL") {
            cardData = component.cards;
          }
        }

        // Update the state with the extracted values
        setheader(templateFormat);
        setbody(bodyText);
        setfooter(footer);
        setbuttons(totalbutton);
        setTitle(headerText); // Assuming Title is set from headerText
        settemplateid(templateData.id);
        setcardData(cardData);
      }
    } else {
      // Reset states if no template is selected
      setheader("");
      setbody("");
      setfooter("");
      setbuttons([]);
      setTitle("");
      settemplateid("");
      setcardData();
    }
  };

  const formatText = (text) => {
    return text
      .replace(/\*(.*?)\*/g, "<b>$1</b>") // Bold
      .replace(/_(.*?)_/g, "<i>$1</i>") // Italic
      .replace(/~(.*?)~/g, "<s>$1</s>"); // Strikethrough
  };

  const handleMouseLeave = (event, option) => {
    setSelectedType(null);
  };
  console.log("selected list is :", selectedList);
  console.log("selected type is :", selectedType);
  console.log("selecetd template id is :", istemplateid);
  let content;
  if (isheader.toUpperCase() === "IMAGE") {
    content = <IoImageSharp className="h-40 w-52 bg-orange-300 rounded-md" />;
  } else if (isheader.toUpperCase() === "VIDEO") {
    content = (
      <div className="text-4xl text-blue-600 h-40 w-52 rounded-md">
        <IoPlayCircle className="h-40 w-52" />
      </div>
    );
  } else if (isheader.toUpperCase() === "DOCUMENT") {
    content = <IoDocumentText className="h-40 w-52 text-red-400 " />;
  } else if (isheader.toUpperCase() === "LOCATION") {
    content = (
      <div className="text-4xl text-red-600 h-40 w-52 rounded-md flex items-center justify-center">
        <FaLocationDot className=" text-2xl h-24 w-52 text-red-600" />
      </div>
    );
  }
  // else if (isheader.toUpperCase() === "TEXT") {
  //   content = (
  //     <div className=" w-full bg-white">
  //       <p className="font-whatsapp-bold font-bold ml-2">
  //         {istitle.toLowerCase()}
  //       </p>
  //     </div>
  //   );
  // }
  else if (!isheader) {
    content = <div></div>;
  }

  const [parentObjectsArray, setParentObjectsArray] = useState([]);
  const [hoveredOption, setHoveredOption] = useState(null);

  const handleObjectsArrayChange = (newObjectsArray) => {
    setParentObjectsArray(newObjectsArray);
    console.log("Updated objectsArray in parent:", newObjectsArray);
  };
  console.log("parents parameterlooks like this :", parentObjectsArray);
  //function for getting the media from upload section
  const handlegetmediaurl = (selectedImage) => {
    setselectedmedia(selectedImage);
  };

  useEffect(() => {
    setselectedmedia(null);
  }, [selectedType, istemplateid]);

  let bodyparamter = [];
  if (Array.isArray(parentObjectsArray)) {
    bodyparamter = parentObjectsArray.map((parameter) =>
      parameter.parameterVal.trim()
    );
    console.log("strinded value looks like this :", bodyparamter);
    // return bodyparamter;
  }
  // Ensure these variables are defined in the scope;
  console.log("number in components looks likes this:", mobileNumbers);
  const Data = useMemo(
    () => ({
      id: istemplateid,
      contact: mobileNumbers,
      mediaurl: selectedmedia?.url,
      conatactId: selectedList,
      parameters: bodyparamter,
      latitude: islatitude,
      longitude: islongitude,
      address: isaddress,
      name: islocatioName,
    }),
    [
      istemplateid,
      mobileNumbers,
      selectedmedia?.url,
      selectedList,
      bodyparamter,
      islatitude,
      islongitude,
      isaddress,
      islocatioName,
    ]
  );

  useEffect(() => {
    if (Data) {
      onsend(Data);
    }
  }, [Data, onsend]);

  return (
    <div>
      {/* main code */}
      <div className="flex w-full h-[80%] overflow-hidden justify-between  gap-12  ">
        <div className="mx-16 w-1/2 my-0">
          <TextComponent heading="Template Name" />

          <Autocomplete
            disablePortal
            id="TemplateLanguage"
            options={AcceptedTemplate.map((data) => ({
              name: data.configData.name,
            }))}
            getOptionLabel={(option) => option.name}
            sx={{
              width: "100%",
              marginTop: "15px",
              background: "#F0F0F0",
              borderRadius: "8px",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .css-z8b3ht-MuiFormLabel-root-MuiInputLabel-root": {
                display: "none",
              },
              "& .MuiAutocomplete-input": {
                padding: "7.5px 4px 7.5px 13px",
              },
              "& .MuiFormLabel-root": {
                color: "#A9A9A9",
                fontSize: "medium",
              },
            }}
            renderOption={(props, option) => (
              <li
                {...props}
                onMouseEnter={(event) => handleTypeChange(event, option)} // Mouse move event
                onMouseLeave={(event) => handleMouseLeave(event, option)}
                style={{
                  backgroundColor:
                    hoveredOption === option ? "#e0e0e0" : "white", // Highlight on hover
                }}
              >
                {option.name}
              </li>
            )}
            onChange={handleTypeChange} // Keep original onChange for selection
            renderInput={(params) => (
              <TextField {...params} label="Choose template" />
            )}
          />

          <div className="mt-10">
            <ParamField
              isbody={isbody}
              onObjectsArrayChange={handleObjectsArrayChange}
            />
          </div>

          {isheader.toUpperCase() === "TEXT" ||
          isheader.toUpperCase() === "" ||
          undefined ||
          null ||
          isheader === "Location" ||
          !isheader ? (
            <div></div>
          ) : (
            <div className="mt-10">
              <TextComponent
                heading="Media"
                body1="Size < 5MB, Accepted formats - .png or .jpeg"
              />
              <div className="flex justify-between">
                {selectedmedia?.url.length > 0 ? (
                  <input
                    type="text"
                    placeholder=""
                    value={selectedmedia?.url}
                    className="input input-xs input-bordered w-5/6 h-auto p-2  bg-[#F0F0F0] text-black mt-4 placeholder:font-medium font-normal"
                  />
                ) : (
                  <input
                    type="text"
                    placeholder="Media URL"
                    className="input input-xs input-bordered w-5/6 h-auto p-2 bg-[#F0F0F0] text-black mt-2 placeholder:font-medium font-normal"
                  />
                )}

                <span className="mt-4 mx-2">OR</span>

                <div
                  className="btn btn-sm mt-2 bg-opacity-20 hover:bg-slate-200 shadow-none border-none font-normal text-black"
                  onClick={() =>
                    document.getElementById("mediaSelectInLiveChat").showModal()
                  }
                >
                  <MdPermMedia />
                  Upload Media
                  <SelectMedia isheader={isheader} onsend={handlegetmediaurl} />
                </div>
              </div>
            </div>
          )}

          <div>
            {isheader === "Location" && (
              <div className="mt-7">
                <input
                  type="text"
                  placeholder="latitude"
                  // value={selectedmedia?.url}
                  className="input input-xs input-bordered w-5/6 h-auto p-2  bg-[#F0F0F0] text-black mt-2 placeholder:font-medium font-normal"
                  onChange={(e) => {
                    setlatitude(e.currentTarget.value);
                  }}
                />
                <input
                  type="text"
                  placeholder="longitude"
                  // value={selectedmedia?.url}
                  className="input input-xs input-bordered w-5/6 h-auto p-2  bg-[#F0F0F0] text-black mt-2 placeholder:font-medium font-normal"
                  onChange={(e) => {
                    setlongitue(e.currentTarget.value);
                  }}
                />
                <input
                  type="text"
                  placeholder="name"
                  // value={selectedmedia?.url}
                  className="input input-xs input-bordered w-5/6 h-auto p-2  bg-[#F0F0F0] text-black mt-2 placeholder:font-medium font-normal"
                  onChange={(e) => {
                    setlocationName(e.currentTarget.value);
                  }}
                />
                <input
                  type="text"
                  placeholder="address"
                  // value={selectedmedia?.url}
                  className="input input-xs input-bordered w-5/6 h-auto p-2  bg-[#F0F0F0] text-black mt-2 placeholder:font-medium font-normal"
                  onChange={(e) => {
                    setaddress(e.currentTarget.value);
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div className="w-1/2">
          {selectedType === null || undefined || !selectedType ? (
            <div className="flex justify-center  items-center   overflow-hidden mb-2">
              {/* <span>select your template</span> */}
              <div className="container  flex justify-center">
                <div className="mockup-phone sm:w-[285px]  w-[285px]  lg:w-[285px]  ">
                  <div className="camera "></div>
                  <div className="display bg-white min-h-[450px] relative ">
                    <div className="flex flex-col min-h-[500px]  bg-gray-100">
                      <div className="bg-teal-700 text-white p-2  min-h-[45px] flex items-center">
                        <FaArrowLeft className="mr-2 text-sm text-white mt-4" />

                        <div className="w-8 h-8 bg-yellow-400 rounded-full flex items-center justify-center mr-2 mt-4">
                          <span className="text-black font-bold">T</span>
                        </div>
                        <div className="flex-grow">
                          <div className="font-semibold mt-3">TheBotmode</div>
                          <div className="text-xs  font-normal ">online</div>
                        </div>
                        <div className="flex items-center h-[45px] mt-3 ">
                          <BiVideo className="mx-2 text-lg text-white" />
                          <MdOutlineCall className="mx-1 text-lg text-white" />
                          <IoEllipsisVertical className="mx-1 text-lg text-white" />
                        </div>
                      </div>

                      {/* Chat area */}
                      <div className="flex-grow bg-gray-200 p-4 relative">
                        <img
                          src="https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png"
                          className="h-full w-full object-cover absolute inset-0"
                          alt="whatsapp background"
                        />
                        <div className="  h-[20px] w-[45px]  bg-slate-100 rounded-sm border-1   absolute top-1 left-[40%]  flex items-center justify-center z-[3] bg-opacity-55">
                          <span className="text-xs text-center  font-normal  text-[#54656F]">
                            Today
                          </span>
                        </div>
                        <p className="text-gray-500 absolute  top-14 left-[22%] text-sm">
                          select your template
                        </p>
                      </div>

                      {/* Input area */}
                      <div className=" w-full p-2 flex items-center mb-2 absolute bottom-1 left-0">
                        <div className="flex-grow bg-white rounded-full w-[80%]  py-2 flex items-center">
                          <CgSmileMouthOpen className="text-gray-600 mx-2 text-xl " />
                          <input
                            type="text"
                            placeholder="Type a message"
                            className="w-[60%] outline-none bg-white text-gray-500"
                            readOnly
                            // value="type something "
                            // onChange={(e) => setInputValue(e.target.value)}
                          />
                          {/* <Paperclip className="text-gray-600 mx-2" /> */}
                          <BsPaperclip className="text-gray-600  mx-2 text-lg" />

                          {/* <div className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center"> */}
                          {/* <img src="/api/placeholder/32/32" alt="Camera icon" className="w-5 h-5" /> */}
                          <MdOutlineCameraAlt className="text-gray-600  mx-2 text-lg" />
                          {/* </div> */}
                        </div>
                        <div className="w-8 h-8 bg-teal-700 rounded-full flex items-center justify-center ml-1 ">
                          {/* <Mic className="text-white" /> */}
                          <IoMdMic className="text-white text-lg " />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : iscardData ? (
            <div></div>
          ) : (
            <div>
              <div className="container  flex justify-center">
                <div className="mockup-phone sm:w-[285px]  w-[285px]  lg:w-[285px]  ">
                  <div className="camera "></div>
                  <div className="display bg-white min-h-[450px] relative ">
                    <div className="flex flex-col min-h-[500px]  bg-gray-100">
                      <div className="bg-teal-700 text-white p-2  min-h-[45px] flex items-center">
                        <FaArrowLeft className="mr-2 text-sm text-white mt-4" />

                        <div className="w-8 h-8 bg-yellow-400 rounded-full flex items-center justify-center mr-2 mt-4">
                          <span className="text-black font-bold">T</span>
                        </div>
                        <div className="flex-grow">
                          <div className="font-semibold mt-3">TheBotmode</div>
                          <div className="text-xs  font-normal ">online</div>
                        </div>
                        <div className="flex items-center h-[45px] mt-3 ">
                          <BiVideo className="mx-2 text-lg text-white" />
                          <MdOutlineCall className="mx-1 text-lg text-white" />
                          <IoEllipsisVertical className="mx-1 text-lg text-white" />
                        </div>
                      </div>

                      {/* Chat area */}
                      <div className="flex-grow bg-gray-200 p-4 relative">
                        <img
                          src="https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png"
                          className="h-full w-full object-cover absolute inset-0"
                          alt="whatsapp background"
                        />
                        <div className="  h-[20px] w-[45px]  bg-slate-100 rounded-sm border-1   absolute top-1 left-[40%]  flex items-center justify-center z-[3] bg-opacity-55">
                          <span className="text-xs text-center  font-normal  text-[#54656F]">
                            Today
                          </span>
                        </div>
                        <div className="absolute rounded-t-md top-15 left-3 w-10/12 mx-auto max-h-[calc(100%-96px)] overflow-y-auto custom-scroll ">
                          <div className="bg-white shadow p-2 text-center overflow-hidden">
                            {/* {selectedType !== "None" && selectedType !== "Text" && ( */}
                            <div className="w-auto  bg-[#F8F8F8] rounded-md flex gap-5  justify-center items-center ">
                              {selectedmedia?.url.length > 0 ? (
                                selectedmedia?.mediaType === "image" ? (
                                  <figure>
                                    <img
                                      src={selectedmedia?.url}
                                      alt="loading image"
                                      className="h-40 w-96 object-cover  rounded-md cursor-pointer"
                                    />
                                  </figure>
                                ) : selectedmedia?.mediaType === "video" ? (
                                  <figure>
                                    <video
                                      src={selectedmedia?.url}
                                      controls
                                      className="h-40 w-80 object-cover cursor-pointer"
                                    />
                                  </figure>
                                ) : selectedmedia?.mediaType === "document" ? (
                                  <div className="w-96 h-40">
                                    <iframe
                                      src={selectedmedia?.url}
                                      className="w-full h-full"
                                      title="PDF Preview"
                                      frameBorder="0"
                                      scrolling="auto"
                                    ></iframe>
                                  </div>
                                ) : null
                              ) : (
                                <figure className=" ">{content}</figure>
                              )}
                            </div>
                            {/* )} */}
                            <div className="text-sm  font-whatsapp-bold text-start mt-3">
                              {istitle}
                            </div>
                            <div className="font-normal text-sm mt-3 text-start overflow-hidden">
                              <div
                                className="overflow-hidden  "
                                dangerouslySetInnerHTML={{
                                  __html: formatText(isbody),
                                }}
                              />
                            </div>
                            <div className="text-xs font-normal text-start text-blue-600 mt-4">
                              {isfooter}
                            </div>
                          </div>

                          {buttons.map((button, index) => (
                            <div key={index} className="">
                              {button.type === "PHONE_NUMBER" && (
                                <div className="templatebuttons flex gap-1 items-center justify-center border-blue border-1">
                                  <MdOutlineCall className="text-blue-600 text-sm" />
                                  {button.text}
                                </div>
                              )}
                              {button.type === "url" && (
                                <div className="templatebuttons flex gap-1 items-center justify-center border-blue border-1">
                                  <BiLinkExternal className="text-blue-600 text-sm" />{" "}
                                  {button.text}
                                </div>
                              )}
                              {button.type === "QUICK_REPLY" && (
                                <div className="templatebuttons rounded-b-md flex gap-1 items-center justify-center border-blue border-1">
                                  <CgMailReply className="text-blue-600 text-sm" />
                                  {button.text}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                        <div className="h-6 w-6 rounded-full bg-gray-500 bg-opacity-30 absolute top-[35%] ml-1 right-1.5 flex items-center justify-center">
                          <LuForward className="text-white text-sm" />
                        </div>
                      </div>

                      {/* Input area */}
                      <div className=" w-full p-2 flex items-center mb-2 absolute bottom-1 left-0">
                        <div className="flex-grow bg-white rounded-full w-[80%]  py-2 flex items-center">
                          <CgSmileMouthOpen className="text-gray-600 mx-2 text-xl " />
                          <input
                            type="text"
                            placeholder="Type a message"
                            className="w-[60%] outline-none bg-white "
                            readOnly
                            // value={inputValue}
                            // onChange={(e) => setInputValue(e.target.value)}
                          />
                          {/* <Paperclip className="text-gray-600 mx-2" /> */}
                          <BsPaperclip className="text-gray-600  mx-2 text-lg" />

                          {/* <div className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center"> */}
                          {/* <img src="/api/placeholder/32/32" alt="Camera icon" className="w-5 h-5" /> */}
                          <MdOutlineCameraAlt className="text-gray-600  mx-2 text-lg" />
                          {/* </div> */}
                        </div>
                        <div className="w-8 h-8 bg-teal-700 rounded-full flex items-center justify-center ml-1 ">
                          {/* <Mic className="text-white" /> */}
                          <IoMdMic className="text-white text-lg " />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="modal-action">
        <form method="dialog">
          <button
            className="btn btn-md bg-[#0A474C] text-white hover:bg-[#132f31] hover:text-white border-none w-40 me-5"
            onClick={trigger}
          >
            {data.button1}
          </button>
          {/* if there is a button in form, it will close the modal */}
          <button className="btn">{data.button2}</button>
        </form>
      </div>
    </div>
  );
};

function SelectMedia({ isheader, onsend }) {
  //hooks to delete media
  const { deletedMedia, isloading, deletemedia, setDeleteMedia } =
    useDeleteMedia();
  //hooks to post media
  const { isuploadmedia, ismediaLoader, uploadmedia, setuploadmedia } =
    usePostmedia();
  //hooks to fetch data for media
  const { ismedia, isloader, refetch } = useFetchMedia(
    `${isheader.toLowerCase()}`
  );
  const [isupload, setupload] = useState("");
  //function to upload media
  const handleuploadmedia = async (event) => {
    const file = event.target.files[0];
    setupload(file);

    try {
      const formData = new FormData();
      formData.append("file", file);
      console.log("data in formdata looks like this :", formData);
      await uploadmedia(
        formData // Directly using the file from the event
      );
      refetch();
    } catch (error) {
      // console.error("Error uploading template:", error);
      toast.error("Failed to upload template. Please try again.");
    }
  };
  console.log("uploaded file looks like this :", isupload);

  //function to delete media
  const handleDeletemedia = async () => {
    try {
      const id = selectedImage.id;
      await deletemedia({ id });
      document.getElementById("deletemedia").close();
      refetch();
    } catch (error) {
      toast.error("something went wrong..");
    }
  };

  console.log("media is :", ismedia);
  // const [selectedImage, setSelectedImage] = useState(null);
  // const handleClick = (data) => {
  //   setSelectedImage((prevSelected) =>
  //     prevSelected === data.url ? null : data.url
  //   );
  // };
  const [selectedImage, setSelectedImage] = useState({
    url: null,
    mediaType: null,
    id: null,
  });
  const handleClick = (data) => {
    setSelectedImage((prevSelected) => {
      if (prevSelected.url === data.url) {
        return { url: null, mediaType: null, id: null }; //Deselect if already selected
      } else {
        return { url: data.url, mediaType: data.mediaType, id: data._id }; //Select new media
      }
    });
  };

  console.log("selected image :", selectedImage);

  const handlesendmedia = () => {
    if (selectedImage) {
      onsend(selectedImage);
      // document.getElementById(`mediaSelectInLiveChat`).close();
    } else {
      toast.error("Please select an image first.");
    }
  };

  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (isuploadmedia || deletedMedia) {
      // Show the message when upload or delete is successful
      setShowMessage(true);

      // Hide the message after 4 seconds
      const timer = setTimeout(() => {
        setShowMessage(false);

        // // Optionally reset states if needed
        if (isuploadmedia) {
          // Reset isuploadmedia if needed (optional based on your state logic)
          setuploadmedia(null); // Reset upload media state
        }

        if (deletedMedia) {
          // Reset deletedMedia if needed (optional based on your state logic)
          setDeleteMedia(null); // Reset deleted media state
        }
      }, 2000);

      // Clean up the timer when component unmounts or when media state changes
      return () => clearTimeout(timer);
    }
  }, [isuploadmedia, deletedMedia]);

  return (
    <>
      <dialog id="mediaSelectInLiveChat" className="modal ">
        <div className="modal-box h-screen min-w-[60%] bg-white text-black flex flex-col">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </form>
          <h3 className="font-bold text-lg text-green-900">Media Library</h3>
          <div className="w-full flex justify-end gap-3 mt-3">
            {showMessage && isuploadmedia && !ismediaLoader && (
              <div className="fixed top-12 left-1/2 transform -translate-x-1/2 bg-white border border-gray-200 shadow-md px-6 py-3 rounded-lg flex items-center gap-3 z-50">
                <div className="bg-green-100 rounded-full p-2">
                  <IoIosCheckmarkCircle className="text-green-500 text-2xl" />
                </div>
                <span className="font-medium text-gray-800 text-sm">
                  {isuploadmedia.message}
                </span>
              </div>
            )}
            {showMessage && deletedMedia && !isloader && (
              <div className="fixed top-12 left-1/2 transform -translate-x-1/2 bg-white border border-gray-200 shadow-md px-6 py-3 rounded-lg flex items-center gap-3 z-50">
                <div className="bg-green-100 rounded-full p-2">
                  <IoIosCheckmarkCircle className="text-green-500 text-2xl" />
                </div>
                <span className="font-medium text-gray-800 text-sm">
                  {deletedMedia.message}
                </span>
              </div>
            )}

            <button
              className="btn btn-sm bg-[#0A474C] text-white border-none rounded-md hover:bg-[#173b3e]"
              onClick={handleuploadmedia}
              disabled={ismediaLoader}
              type="button"
            >
              <MdOutlineCloudUpload className="text-xl text-white" />
              <input type="file" id="import" onChange={handleuploadmedia} />
              <label htmlFor="import"> Upload Media</label>
            </button>
            <button
              className="btn btn-sm bg-[#0A474C] text-white border-none rounded-md hover:bg-[#173b3e]   "
              type="button"
            >
              <MdDeleteForever
                className="text-xl text-white"
                onClick={() =>
                  document.getElementById("deletemedia").showModal()
                }
              />
              <dialog id="deletemedia" className="modal">
                <div className="modal-box">
                  <form method="dialog">
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                      ✕
                    </button>
                  </form>

                  <div className="mx-auto flex justify-center">
                    <RiDeleteBin5Fill className="text-4xl text-gray-500" />
                  </div>
                  <p className="mx-auto text-lg text-center p-3 text-black">
                    Are you sure you want to delete this media?
                  </p>
                  <button
                    className="btn btn-sm bg-[#DC2C2B] text-white border-none rounded-md hover:bg-[#DC2C2B]"
                    onClick={handleDeletemedia}
                    disabled={isloading}
                  >
                    <RiDeleteBin5Fill className="text-lg text-white" />
                    {/* <span className="gap-1 text-lg">
                    {isLoading ? "Deleting..." : "Yes, I'm sure"}
                  </span> */}

                    <span className="gap-1 text-lg">Yes, I'm sure</span>
                  </button>
                </div>
              </dialog>
            </button>
          </div>
          <div role="tablist" className="tabs tabs-bordered mt-4 w-[10%]">
            <a role="tab" className="tab tab-active justify-start">
              {isheader}
            </a>
          </div>
          <span className="text-green-800 mt-5 text-right mr-4">
            All {isheader}({ismedia.length})
          </span>

          {/* Scrollable content container */}
          <div className="flex-1 overflow-y-auto mt-3">
            <div className="mx-auto flex flex-wrap justify-evenly gap-3">
              {ismedia?.map((data) => (
                <div key={data._id} className="relative">
                  {data.mediaType === "image" ? (
                    <img
                      src={data.url}
                      alt={`Image ${data._id}`}
                      className={`h-28 w-32 rounded object-cover cursor-pointer ${
                        selectedImage.url === data.url &&
                        selectedImage.mediaType === data.mediaType
                          ? "border-4 border-[#173b3e]"
                          : ""
                      }`}
                      onClick={() => handleClick(data)}
                    />
                  ) : data.mediaType === "video" ? (
                    <video
                      src={data.url}
                      className={`h-28 w-32 rounded object-cover cursor-pointer ${
                        selectedImage.url === data.url &&
                        selectedImage.mediaType === data.mediaType
                          ? "border-4 border-[#173b3e]"
                          : ""
                      }`}
                      onClick={() => handleClick(data)}
                      controls
                    />
                  ) : data.mediaType === "document" ? (
                    <div>
                      <div
                        className={`
                    h-40 w-36 flex flex-col items-center justify-between p-4 rounded-lg
                    bg-white shadow-md hover:shadow-lg transition-shadow duration-300
                    cursor-pointer overflow-hidden
                    ${
                      selectedImage.url === data.url &&
                      selectedImage.mediaType === data.mediaType
                        ? "border-4 border-[#173b3e]"
                        : ""
                    }
                  `}
                        onClick={() => handleClick(data)}
                      >
                        {/* <File size={32} className="text-gray-600" /> */}
                        <IoDocumentText className="text-pink-500 text-5xl " />

                        <p className="text-center text-sm font-medium text-gray-800 truncate w-full">
                          {data.filename}
                        </p>

                        <a
                          href={data.url}
                          target="_blank"
                          download
                          className="mt-2 text-md text-blue-600 hover:text-blue-800 flex items-center gap-2 justify-center w-full"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent event propagation
                          }}
                        >
                          <MdDownload className="text-2xl text-blue-950" />
                          download
                        </a>
                      </div>
                      <div>
                        <a
                          href={data.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mt-2 text-sm text-blue-600 hover:text-blue-800 flex items-center justify-center w-full"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <span className="flex gap-2" type="button">
                            <MdVisibility
                              className="text-lg text-blue-500"
                              type="button"
                            />
                            View
                          </span>
                        </a>
                      </div>
                    </div>
                  ) : null}
                  {selectedImage.url === data.url &&
                    selectedImage.mediaType === data.mediaType && (
                      <MdCheckCircle className="absolute -top-4 left-0.5 transform -translate-x-1/2 text-2xl text-[#173b3e]" />
                    )}
                </div>
              ))}
            </div>
          </div>

          <div className="w-full bg-white h-12 fixed bottom-0 left-0 right-0 flex justify-end items-center">
            {selectedImage ? (
              <form method="dialog">
                {/* if there is a button in form, it will close the modal */}
                <button
                  className="btn btn-sm bg-green-800 text-white  mr-5  w-32  flex justify-center items-center"
                  onClick={handlesendmedia}
                >
                  send
                </button>
              </form>
            ) : (
              <button
                className="btn btn-sm bg-green-800 text-white  mr-5  w-[15%]  flex justify-center items-center"
                onClick={handlesendmedia}
              >
                send
              </button>
            )}
          </div>
        </div>
      </dialog>
    </>
  );
}

export default BulkMessageBox;

function ParamField({ isbody, onObjectsArrayChange }) {
  const [inputValue, setInputValue] = useState(isbody);

  useEffect(() => {
    setInputValue(isbody);
    handleChange(isbody);
  }, [isbody]);
  console.log("Template body text:", inputValue);

  const [objectsArray, setObjectsArray] = useState([]);

  console.log("parameter looks like this :", objectsArray);

  const handleChange = (inputValue) => {
    // const value = e.target.value;

    const value = inputValue;

    const regex = /{{(\d+)}}/g;
    let match;
    const idsFound = new Set();

    // Extract existing IDs
    while ((match = regex.exec(value)) !== null) {
      idsFound.add(parseInt(match[1], 10));
    }

    // Sort the IDs and create new sequential IDs
    const sortedIds = Array.from(idsFound).sort((a, b) => a - b);
    const idMapping = new Map();
    let currentId = 1;
    sortedIds.forEach((originalId) => {
      idMapping.set(originalId, currentId++);
    });

    // Replace the original IDs with the new sequential IDs
    let modifiedValue = value.replace(
      regex,
      (match, p1) => `{{${idMapping.get(parseInt(p1, 10))}}}`
    );

    // Create a lookup map from objectsArray
    const lookupMap = new Map(
      objectsArray.map((item) => [item.id, item.parameterVal])
    );

    // Update the new objects array with sequential IDs
    const newObjectsArray = Array.from(idMapping.values()).map((id) => ({
      id,
      parameterVal: lookupMap.get(id) || "", // Use existing value if it exists, otherwise empty string
    }));

    setInputValue(modifiedValue);
    setObjectsArray(newObjectsArray);
  };

  useEffect(() => {
    handleChange(inputValue);
  }, [inputValue]);

  useEffect(() => {
    onObjectsArrayChange(objectsArray);
  }, [objectsArray, onObjectsArrayChange]);

  const handleParameterValChange = (event, id, newValue) => {
    setObjectsArray(
      // New Method
      objectsArray.map((item) =>
        item.id === id ? { ...item, parameterVal: newValue.label } : item
      )

      // old method
      // objectsArray.map((item) => {
      //   if (item.id === id) {
      //     return { ...item, parameterVal: newValue };
      //   } else {
      //     return item;
      //   }
      // })
    );
  };

  const TheadingData = [
    { label: "name", id: 1 },
    { label: "mobile_Number", id: 2 },
  ];

  return (
    <>
      <TextComponent
        heading="Parameters"
        // body="You can personalize messages with - $FirstName, $Name, $MobileNumber etc."
        body1="You can personalize messages with - $FirstName, $Name, $MobileNumber etc."
      />
      {objectsArray.length > 0 && (
        <div className="mt-5">
          {objectsArray.map((item) => (
            <div className="flex justify-between items-center" key={item.id}>
              <div className="text-lg w-1/6 font-normal mt-5 pe-3">{`{{${item.id}}}`}</div>
              {/* <input
                value={item.parameterVal}
                onChange={(event) => {
                  handleParameterValChange(event, item.id);
                }}
                type="text"
                placeholder="Enter parameter value"
                className="input input-xs input-bordered w-5/6 h-auto p-2 bg-[#F0F0F0] text-black mt-2 placeholder:font-medium font-normal"
              /> */}

              <div className="w-5/6">
                <Autocomplete
                  disablePortal
                  id="TemplateParamiters"
                  options={TheadingData}
                  getOptionLabel={(option) => option.label}
                  sx={{
                    width: "100%",
                    marginTop: "20px",
                    background: "#F0F0F0",
                    height: "35px",
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .css-z8b3ht-MuiFormLabel-root-MuiInputLabel-root": {
                      // display: "none",
                    },
                    "& .css-8ujita-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input":
                      {
                        padding: "0px !important",
                      },
                    "& .css-1b9mdof-MuiFormLabel-root-MuiInputLabel-root": {
                      color: "#A9A9A9",
                      fontSize: "medium",
                      //   marginTop: "-8px !important",
                    },
                    "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                      //   padding: "0px !important",
                    },
                  }}
                  onChange={(event, newValue) => {
                    handleParameterValChange(event, item.id, newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose Tag"
                      InputLabelProps={{
                        sx: {
                          marginTop: "-8px", // Customize the label margin here
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          padding: "0 10px !important", // Add padding inside the input field
                        },
                      }}
                    />
                  )}
                />
              </div>
            </div>
          ))}
        </div>
      )}

      {/* <button className="btn btn-success" onClick={handleChange}>
        Click
      </button> */}
    </>
  );
}
