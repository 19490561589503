import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

function TabBar({ data }) {
  const [tabActive, SetTabActive] = useState(data.span1);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/template/") {
      onhadleActive(`${data.span1}`);
    } else if (location.pathname === "/template/message/Utility") {
      onhadleActive(`${data.span2}`);
    } else if (location.pathname === "/template/message/Authentication") {
      onhadleActive(`${data.span3}`);
    } else if (location.pathname === "/template/message/pending") {
      onhadleActive(`${data.span4}`);
    } else if (location.pathname === "/template/message/action") {
      onhadleActive(`${data.span5}`);
    } else {
      onhadleActive(`${data.span1}`);
    }
  }, [location.pathname, data]);

  const onhadleActive = (val) => {
    SetTabActive(val);
  };
  return (
    <>
      {data.Logos && (
        <div className={`mt-10 ${data.width ? "w-11/12" : "w-5/6"} mx-auto`}>
          <div role="tablist" className="tabs tabs-bordered">
            <Link
              to={data.Links.link1}
              role="tab"
              className={`tab ${
                tabActive === `${data.span1}` ? "tab-active text-black" : ""
              }`}
              onClick={() => {
                onhadleActive(`${data.span1}`);
              }}
            >
              <div className="flex gap-2 items-center justify-center">
                {data.Logos.logo1}
                {data.span1}
              </div>
            </Link>

            <Link
              to={data.Links.link2}
              role="tab"
              className={`tab ${
                tabActive === `${data.span2}` ? "tab-active text-black" : ""
              }`}
              onClick={() => {
                onhadleActive(`${data.span2}`);
              }}
            >
              <div className="flex gap-2 items-center justify-center">
                {data.Logos.logo2}
                {data.span2}
              </div>
            </Link>

            <Link
              to={data.Links.link3}
              role="tab"
              className={`tab ${
                tabActive === `${data.span3}` ? "tab-active text-black" : ""
              }`}
              onClick={() => {
                onhadleActive(`${data.span3}`);
              }}
            >
              <div className="flex gap-2 items-center justify-center">
                {data.Logos.logo3}
                {data.span3}
              </div>
            </Link>

            <Link
              to={data.Links.link4}
              role="tab"
              className={`tab ${
                tabActive === `${data.span4}`
                  ? "tab-active text-black active:bg-black"
                  : ""
              }`}
              onClick={() => {
                onhadleActive(`${data.span4}`);
              }}
            >
              <div className="flex gap-2 items-center justify-center">
                {data.Logos.logo4}
                {data.span4}
              </div>
            </Link>

            <Link
              to={data.Links.link5}
              role="tab"
              className={`tab ${
                tabActive === `${data.span5}`
                  ? "tab-active text-black active:bg-black"
                  : ""
              }`}
              onClick={() => {
                onhadleActive(`${data.span5}`);
              }}
            >
              <div className="flex gap-2 items-center justify-center">
                {data.Logos.logo5}
                {data.span5}
              </div>
            </Link>


            {data.span6 && (
              <Link
                to={data.Links.link6}
                role="tab"
                className={`tab ${
                  tabActive === `${data.span6}`
                    ? "tab-active text-black active:bg-black"
                    : ""
                }`}
                onClick={() => {
                  onhadleActive(`${data.span6}`);
                }}
              >
                <div className="flex gap-2 items-center justify-center">
                  {data.Logos.logo6}
                  {data.span6}
                </div>
              </Link>
            )}

            {/* <Link
              to={data.Links.link6}
              role="tab"
              className={`tab ${
                tabActive === `${data.span6}`
                  ? "tab-active text-black active:bg-black"
                  : ""
              }`}
              onClick={() => {
                onhadleActive(`${data.span6}`);
              }}
            >
              <div className="flex gap-2 items-center justify-center">
                {data.Logos.logo6}
                {data.span6}
              </div>
            </Link> */}
          </div>
        </div>
      )}
    </>
  );
}

export default TabBar;
