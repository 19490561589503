import { useEffect } from "react";
import io from "socket.io-client";
import useZusConversation from "../zustand/useZusConversation";

const DOMAIN_WHATSAPP_API_UR = "https://app.thebotmode.com";

const socket = io(`${DOMAIN_WHATSAPP_API_UR}`);

const useNewUnseenMessages = () => {
  const {
    selectedConversation,
    setSelectedConversation,
    conversations,
    setConversations,
  } = useZusConversation();
  useEffect(() => {
    socket.on("newUnseenMessages", (newUnseenMessages) => {
      console.log("newUnseenMessages from socket:", newUnseenMessages);
      console.log("conversations:", conversations);

      let data = [];
      if (selectedConversation._id === newUnseenMessages._id) {
        return;
      }

      data = conversations.map((item) =>
        item._id === newUnseenMessages._id
          ? { ...item, ...newUnseenMessages }
          : item
      );

      // console.log("data :", data);

      setConversations(data);
      // console.log("newLsConversations:", conversations);
    });

    return () => socket?.off("newUnseenMessages");
  }, [
    selectedConversation,
    setSelectedConversation,
    conversations,
    setConversations,
  ]);
};

export default useNewUnseenMessages;
