//STARTER CODE OF THIS FILE
import React, { useEffect } from "react";
import Messages from "./Messages";
import MessageInput from "./MessageInput";
import useZusConversation from "../../../zustand/useZusConversation";
import { UseAuthContext } from "../../../context/AuthContext";
import { TiMessages } from "react-icons/ti";
import TemplateMsgSend from "./TemplateMsgSend";

const MessageContainer = () => {
  const { selectedConversation, setSelectedConversation } =
    useZusConversation();
  const { authUser } = UseAuthContext();

  useEffect(() => {
    //cleanup function (unmounts)
    return () => setSelectedConversation(null);
  }, [setSelectedConversation]);

  //Check is chat  expired? code start
  const now = Date.now(); // Current timestamp in milliseconds
  const expiresAt = new Date(selectedConversation?.expiresAt).getTime(); // Convert ISO string to timestamp in milliseconds
  const isChatExpired = now > expiresAt;
  if (isChatExpired) {
    console.log("The expiration date has passed.");
  } else {
    console.log("The expiration date has not yet passed.");
  }
  //Check is chat  expired? code start

  return (
    <div className="live-chat customScroller border-none w-2/4 flex flex-col">
      {selectedConversation ? (
        <>
          {/* HEADER */}
          <div className="bg-[#0A474C] text-base px-4 py-4 mb-2">
            <span className="label-text font-bold text-white">To : </span>
            <span className="text-white font-bold me-2">
              {selectedConversation.name}
            </span>
            <span className="text-gray-200 font-medium">
              (+{selectedConversation.mobile_Number})
            </span>
          </div>
          <Messages />
          {isChatExpired && <TemplateMsgSend />}
          {!isChatExpired && <MessageInput />}
        </>
      ) : (
        <NoChatSelected name={authUser.fullName} />
      )}
    </div>
  );
};

export default MessageContainer;

const NoChatSelected = ({ name }) => {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="px-4 text-center sm:text-lg md:text-xl text-[#0A474C] font-semibold flex flex-col items-center gap-2">
        <p>Welcome 👋 {name} ❄️</p>
        <p>Select a chat to start messaging</p>
        <TiMessages className="text-3xl md:text-6xl text-center" />
      </div>
    </div>
  );
};
