// import React from "react";
// import Conversation from "./Conversation";
// import useGetConversations from "../../hooks/useGetConversations";

// const Conversations = () => {
//   const { loading, conversations } = useGetConversations();

//   console.log("Conversations :", conversations);

//   return (
//     <div className="py-2 flex flex-col overflow-auto">
//       {conversations.map((conversation, idx) => (
//         <Conversation
//           key={conversation._id}
//           conversation={conversation}
//           lastIdx={idx === conversations.length - 1}
//         />
//       ))}

//       {loading ? <span className="loading loading-spinner"></span> : null}
//     </div>
//   );
// };

// export default Conversations;

// STARTER CODE FOR CONVERSATION
import React from "react";
import Conversation from "./Conversation";
import useGetConversations from "../../../hooks/useGetConversations";
import useNewUnseenMessages from "../../../hooks/useNewUnseenMessages";

const Conversations = ({ searchQuery }) => {
  const { loading, conversations } = useGetConversations();

  useNewUnseenMessages();

  const filteredConversations = conversations?.filter((conversation) => {
    return conversation.name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  return (
    <div className="py-2 mt-2 flex flex-col overflow-auto">
      {conversations.length === 0 && (
        <div className="h-[100vh] w-full flex justify-center items-center opacity-55 text-xl">
          <div>No Conversations!</div>
        </div>
      )}

      {!filteredConversations &&
        conversations &&
        conversations.map((conversation, idx) => (
          <Conversation
            key={conversation._id}
            conversation={conversation}
            lastIdx={idx === conversations.length - 1}
          />
        ))}

      {filteredConversations &&
        filteredConversations.map((conversation, idx) => (
          <Conversation
            key={conversation._id}
            conversation={conversation}
            lastIdx={idx === filteredConversations.length - 1}
          />
        ))}

      {loading && <span className="loading loading-dots"></span>}
    </div>
  );
};

export default Conversations;
