import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const useGetContacts = () => {
  const [loadingContact, setLoadingContact] = useState(false);
  const [contacts, setContacts] = useState([]);

  const getContacts = async () => {
    setLoadingContact(true);
    try {
      const res = axios.get("/api/contact/getAllContacts");
      const data = await res;
      setContacts(data.data);
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setLoadingContact(false);
    }
  };

  useEffect(() => {
    getContacts();
  }, []);

  const refreshContacts = () => getContacts(); // Function to toggle the refresh state

  return { loadingContact, contacts, refreshContacts };
};

export default useGetContacts;
