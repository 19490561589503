import { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";

const useUpContacts = () => {
  const [loading, setLoading] = useState(false);

  const uploadContacts = async (contacts) => {
    if (!contacts) return;

    setLoading(true);

    try {
      const res = axios.post("/api/contact/bulk/upload", contacts);
      const data = await res;

      // console.log("res of contacts data", data);

      if (data.data.skipped_to_avoid_repeatation > 0) {
        toast(data.data.message, {
          icon: "👏",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }

      toast.success("Contacts saved successfully!");
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading, uploadContacts };
};

export default useUpContacts;
