import axios from "axios";
import { useEffect, useState } from "react";
import useZusConversation from "../zustand/useZusConversation";
import toast from "react-hot-toast";

const useGetConversations = () => {
  const [loading, setLoading] = useState(false);
  const { conversations, setConversations } = useZusConversation();

  useEffect(() => {
    setLoading(true);

    const getConversations = async () => {
      try {
        const res = axios.get("/api/client/users");

        const data = (await res).data;

        // console.log(data.data);

        setConversations(data);
      } catch (error) {
        toast.error(error.response?.data?.error || error.message);
      } finally {
        setLoading(false);
      }
    };

    getConversations();
  }, []);

  return { loading, conversations, setConversations };
};

export default useGetConversations;
