import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";

const useRefreshSheetData = () => {
  const [loading2, setLoading2] = useState(false);

  const refreshSheetDataFn = async () => {
    try {
      setLoading2(true);

      const res = await axios.get("/api/sheets/data");
      console.log("refreshSheetDataFn:", res.data);
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setLoading2(false);
    }
  };
  return { loading2, refreshSheetDataFn };
};

export default useRefreshSheetData;
