import { useEffect, useState } from "react";
import useZusConversation from "../zustand/useZusConversation";
import toast from "react-hot-toast";
import axios from "axios";

const useGetMessage = () => {
  const [loading, setLoading] = useState(false);
  const { messages, setMessages, selectedConversation } = useZusConversation();

  useEffect(() => {
    const getMessage = async () => {
      try {
        setLoading(true);
        const res = axios.get(
          `/api/client/messages/${selectedConversation._id}`
        );

        const data = (await res).data;

        setMessages(data);
      } catch (error) {
        toast.error(error.response?.data?.error || error.message);
      } finally {
        setLoading(false);
      }
    };

    if (selectedConversation?._id) getMessage();
  }, [setMessages, selectedConversation?._id]);

  return { loading, messages };
};

export default useGetMessage;
